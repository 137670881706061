<template>
    <BlockUI :blocked="loadingCard">
        <div class="surface-card shadow-2 p-3 border-round user_active" @click="detailItem()">
            <div class="flex justify-content-between mb-3">
                <div>
                    <span class="block font-bold text-2xl mb-3 underline">USER AKTIF</span>
                    <div class="text-500 font-medium text-xl">
                        {{total_aktif}}/{{total_user}}
                    </div>
                </div>
                <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                    <i class="pi pi-user text-blue-500 text-xl"></i>
                </div>
            </div>
            <!-- <span class="font-medium">{{percent_aktif}}%</span> -->
             <Badge :value="percent_aktif+'%'" class="mr-2" size="large" severity="primary"></Badge>
            <!-- <span class="text-pink-500"> Detail</span> -->
        </div>
    </BlockUI>

    <Dialog v-model:visible="detailItemDialog" :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '50vw' }" header="Data Detail" :modal="true" :maximizable="true">
        <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="username" @sort="onSort($event)" :resizableColumns="true" columnResizeMode="fit">
            <template #header>
                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                    <div>
                        <!-- <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                        <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" /> -->
                    </div>
                    <span class="block mt-2 md:mt-0 p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                    </span>
                </div>
            </template>
            <Column field="number" header="No." :style="{width:'20px'}" >
                <template #body="slotProps">
                {{ slotProps.data.number }}
                </template>
            </Column>
            <Column field="level_name" header="Level">
                <template #body="slotProps">
                {{ slotProps.data.level_name }}
                </template>
            </Column>
            <Column field="username" header="Username">
                <template #body="slotProps">
                {{ slotProps.data.username }}
                </template>
            </Column>
            <Column field="names" header="Nama">
                <template #body="slotProps">
                {{ slotProps.data.names }}
                </template>
            </Column>
            <Column field="se_in_location" header="Lokasi">
                <template #body="slotProps">
                {{ slotProps.data.se_in_location }}
                </template>
            </Column>
            <Column field="se_in_time" header="Start Time">
                <template #body="slotProps">
                {{ slotProps.data.se_in_time }}
                </template>
            </Column>
            <template #empty>
                No data found.
            </template>
            <template #loading>
                Loading data. Please wait.
            </template>
            <template #footer>
                In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
            </template>
        </DataTable>

        <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
            <template>
                {{totalItemsCount}}
            </template>
        </Paginator>

        <template #footer>
            <Button label="Batal" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
        </template>

    </Dialog>
</template>

<script>

import moment from "moment";
import { numberingDatatable } from "../../utils/helpers";

export default {
    data() {
        return {
            loading: false,
            loadingCard: false,

            user: null,
            total_aktif: null,
            total_user: null,
            percent_aktif: null,

            //detail user
            detailItemDialog: false,

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getData();
        this.$store.commit('setErrors', {});
    },
    created(){

    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        },
    },
    computed:{
    },
    methods:{
        formatDate(value) {
            if (value) {
                return moment.utc(value).format("DD-MM-YYYY");
            }
        },
        getData(){

            this.loadingCard=true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/dashboard/user-aktif-count',
                params: {
                }
            })
            .then(res => {
                this.user = res.data.data[0];
                this.total_aktif = this.user.total_aktif;
                this.total_user = this.user.total_user;
                this.percent_aktif = this.user.percent_aktif;
                this.loadingCard= false;
            })
            .catch((err) => {
                this.loadingCard= false;
                console.log(err);
            });
        },
        // DATATABLE
        detailItem() {
            this.clearDataTable();
            this.$store.commit('setErrors', {});

            this.getDataTable();
            this.detailItemDialog = true;
        },
        hideDialog() {
            this.detailItemDialog = false;
        },
        //
        getDataTable(){
          
            this.loading=true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/dashboard/user-aktif-count-detail',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loading=false;
            })
            .catch((err) => {
                console.log(err);
                this.loading=false;
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        clearDataTable(){
            this.data= null;
            this.dataTable= null;
            this.rows= 10;
            this.search= null;
            this.offset= null;
            this.field= null;
            this.sort= null;
            this.page= null;
            this.totalItemsCount= 0;
        }
    }
}
</script>

<style>
.user_active{
    transition: all 0.3s; /* untuk memberikan efek transisi saat hover */
}
.user_active:hover {
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.2); /* meningkatkan efek shadow */
    transform: scale(1.01); /* mengubah skala card */
    cursor: pointer; /* mengubah cursor menjadi bentuk pointer saat hover */
}
</style>
