<template>
	<Error :errors="errors"/>
	<div class="grid">
		<div class="col-12 lg:col-12 xl:col-12">
			<div class="surface-section px-4 py-8 md:px-6 lg:px-8">
				<div class="text-700 text-center">
					<div class="text-blue-600 font-bold mb-3">&nbsp;SELAMAT DATANG DI</div>
					<div class="text-900 font-bold text-5xl mb-3">Web Report - {{appsName}}</div>
				</div>
			</div>
		</div>
	</div>
	<div class="grid">
		<div class="col-12 md:col-6 lg:col-3">
			<UserAktif/>
        </div>
		<div class="col-12 md:col-6 lg:col-3">
			<CallPlan/>
        </div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import UserAktif from '../components/dashboard/UserAktif.vue';
import CallPlan from '../components/dashboard/CallPlan.vue';
import Error from '../components/Error.vue';

export default {
	components: {
		'UserAktif': UserAktif,
		'CallPlan': CallPlan,
		'Error': Error,
	},
    computed:{
        ...mapGetters(['errors']),
    },
	data() {
		return {		
			appsName: process.env.VUE_APP_APPS_NAME,
		}
	},
}
</script>